<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :btnFlag="btnFlag"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      ref="appList"
      @selectChange="getSelectChangeInfo"
    >
      <div class="search" slot="subSlot"></div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    return {
      selecTionArr: [],
      remote: api,
      apiName: "/link",
      searchVal: "",
      bntLoading: false,
      btnFlag: { addShow: true, delShow: false },
      searchQuery: {
        name: "",
        classificationGoodsId: "",
        refreshNum: 0,
        type:1
      },
      rules: {
        //传递给APPlist的表单验证
      },
      props: [
        {
          label: "名称",
          prop: "title",
          align: "center",
        },

        {
          label: "图片类型",
          prop: "type",
          align: "center",
          type: "html",
          formatter: function (row) {
            const map = ["幻灯片", "菜单图标"];
            return "<span>" + map[row.type] + "</span>";
          },
        },

        {
          label: "主图",
          prop: "picPath",
          align: "center",
          type: "html",
          formatter: function (row) {
            if (row.picPath.indexOf(",") !== -1) {
              return (
                "<img src=api/" +
                row.picPath.split(",")[0] +
                " class='tablePic'/>"
              );
            } else {
              return "<img src=api/" + row.picPath + " class='tablePic'/>";
            }
          },
        },

        {
          label: "链接类型",
          prop: "linkType",
          align: "center",
          type: "html",
          formatter: function (row) {
            const map = ["页面", "商品分类", "商品详情"];
            return "<span>" + map[row.linkType] + "</span>";
          },
        },

        {
          label: "是否上架",
          prop: "isUse",
          align: "center",
          type: "html",
          formatter: function (row) {
            const map = ["启用", "停用"];
            return "<span>" + map[row.isUse] + "</span>";
          },
        },
        {
          label: "链接",
          prop: "url",
          align: "center",
        },

        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () {},
              },
            ];

            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "名称",
          type: "input",
          prop: "title",
        },
        {
          label: "类型",
          type: "select",
          prop: "type",
          default: 1,
          disabled:true,
          selectData: [
            {
              label: "幻灯片",
              value: 0,
            },
            {
              label: "菜单图标",
              value: 1,
            },
          ],
        },
        {
          label: "链接类型",
          type: "select",
          prop: "linkType",
          selectData: [
            {
              label: "页面",
              value: 0,
            },
            {
              label: "分类",
              value: 1,
            },
            {
              label: "商品",
              value: 2,
            },
          ],
        },
        {
          label: "链接",
          type: "input",
          prop: "url",
        },

        {
          label: "排序",
          type: "input",
          prop: "sort",
        },

        {
          label: "启用",
          type: "radio",
          prop: "isUse",
          default:0,
          selectData: [
            { label: "启用", value: 0 },
            { label: "停用", value: 1 },
          ],
        },
        {
          label: "图片",
          type: "upload",
          prop: "picPath",
          limit: 1,
          notice: "jpg或png格式，图片比例2.15：1",
        },
      ],
      goodsList: [],
      categoryList: [],
    };
  },
  created() {},
  mounted() {
    this.getCategoryList();
    this.getGoodsList();
  },
  methods: {
    async getGoodsList() {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      try {
        let res = await api.commonGet({
          apiName: "/goodsBasicSetting",
          pageSize: 100,
        });

        this.goodsList = [];
        res.data.data.forEach((element) => {
          this.goodsList.push({ label: element.name, value: element.id });
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    async getCategoryList() {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      try {
        let res = await api.commonGet({
          apiName: "/classificationGoods",
          pageSize: 100,
        });

        res.data.forEach((element) => {
          this.categoryList.push({ label: element.name, value: element.id });
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    getSelectChangeInfo(e, field) {
      if (field == "linkType") {
        //分类
        if (e == 1) {
          this.formProps[3] = {
            label: "链接",
            type: "select",
            prop: "url",
            selectData: this.categoryList,
          };
        } else if (e == 2) {
          this.formProps[3] = {
            label: "链接",
            type: "select",
            prop: "url",
            selectData: this.goodsList,
          };
        } else {
          this.formProps[3] = {
            label: "链接",
            type: "input",
            prop: "url",
          };
        }
      }
      console.log(e, field);
    },
    jumpToDetail(id) {
      this.$router.push({ path: "/goods/create", query: { id: id } });
    },

    async setGoodsPutAway(value) {
      if (!this.$refs.appList.selecTionArr.length) {
        this.$message.error("请至少选择一条数据！");
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });

      try {
        let ids = [];
        this.$refs.appList.selecTionArr.forEach((element) => {
          ids.push(element.id);
        });

        let res = await api.common.commonPost({
          apiName: "/goodsBasicSetting/isPutaway",
          ids: ids,
          isPutaway: value,
        });
        this.$message.success(res.message);
        this.searchQuery.refreshNum++;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
